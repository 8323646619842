<template>
    <div class="new-message-modal">
        <div class="close-wrapper">
            <img
                src="@/assets/images/close.svg"
                alt="close"
                class="close"
                @click="$emit('close')"
            />
        </div>

        <h1 class="new-message-title">{{ $t('titleMessageModal') }}</h1>
        <form action="" @submit.prevent="sendNewMessage">
<!--            <md-field class="custom-input">-->
<!--                <label>Subject</label>-->
<!--                <md-input-->
<!--                    type="text"-->
<!--                    v-model="message.subject"-->
<!--                    placeholder="Subject"-->
<!--                />-->
<!--            </md-field>-->
            <md-field class="custom-textarea">
                <label>Message</label>
                <md-textarea
                    type="text"
                    v-model="message.description"
                    :placeholder="$t('placeholder')"
                    required
                />
            </md-field>

            <div class="new-message-action">
                <button type="submit" class="new-message-btn btn-send" :class="inPending ? 'disable-btn' : ''" :disabled="inPending">{{ $t('btnSend') }}</button>
                <button class="new-message-btn btn-cancel" @click="$emit('close')">{{ $t('btnCancel') }}</button>
            </div>
            <Errors :errors="errors"></Errors>
        </form>

    </div>
</template>
<script>
import Errors from "@/components/Errors"
import {errors} from "@/mixins/errors"
export default {
    name: 'new-message-modal',
    data: () => ({
        inPending: false,
        message: {
            // subject: '',
            id: 0,
            description: ''
        }
    }),

    components: {
        Errors
    },

    mixins: [
        errors,
    ],

    methods: {
        sendNewMessage() {
//            this.clearErrors()
//            this.inPending = true
//            this.$axios
//                .post(process.env.VUE_APP_API_URL + '/bgfMessage/Edit', this.message)
//                .then((response) => {
//                    if(response.data.success) {
//                        this.inPending = false
//                        this.$emit('saved')
//                    } else {
//                        this.setErrors([response.data.error])
//                    }
//                })
//                .catch(err => {
//                    this.setErrors(err.response.data.errors)
//                })
        }
    }
}
</script>
<style>
.disable-btn {
  background-color: #aaafbd!important;
}
</style>
<style lang="scss" scoped>
.new-message-modal {
    padding: 25px;
    background-color: #FFFFFF;
    width: 40%;

    .close {
        width: 14px;
        cursor: pointer;

        &-wrapper {
            display: flex;
            justify-content: flex-end;
        }
    }

    .new-message-title {
        font-weight: bold;
        font-size: 30px;
        line-height: 43px;
        margin-bottom: 45px;
    }
    .custom-input {
        border: 1px solid #CDD0D9;
        height: 48px;
        font-size: 16px;
        line-height: 24px;
        border-radius: 6px;
        padding: 0;
        margin-bottom: 45px;

        label {
            opacity: 1;
            font-size: 16px;
            line-height: 24px;
            color: black;
            top: -25px;
        }
        input {
            height: 100%;
            padding-left: 25px;
        }
    }

    .md-field.md-theme-default:before {
        background-color: unset;
    }

    .custom-textarea{
        border: 1px solid #CDD0D9;
        height: 220px;
        font-size: 16px;
        line-height: 24px;
        border-radius: 6px;
        padding: 0;
        margin-bottom: 20px;

        label {
            opacity: 1;
            font-size: 16px;
            line-height: 24px;
            color: black;
            top: -25px!important;
            left: 0;
        }
        textarea {
            height: 100%;
            padding: 20px 25px!important;
        }
    }
    .md-field.md-theme-default.md-has-textarea:not(.md-autogrow):before {
        border: none;
    }
    .md-field.md-theme-default.md-has-textarea:not(.md-autogrow):after {
        border: none;
    }
    .new-message-action {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .new-message-btn {
        width: 120px;
        height: 48px;
        border-radius: 6px;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        outline: none;
        border: none;
        cursor: pointer;
    }
    .btn-send {
        color: #FFFFFF;
        background-color: #0045FF;
    }
    .btn-cancel {
        color: black;
        background-color: #FFFFFF;
        border: 1px solid #CDD0D9;
        margin-left: 15px;
    }
}
</style>
