<template>
    <div class="messages-page_wrapper">
        <div class="messages-page">
            <div class="messages-page_header">
                <h1 class="messages-title">Messages</h1>
                <button
                    class="new-messages_btn"
                    @click="showCreateNewMessageModal = true"
                >
                    <span>{{ $t('titleMessageModal') }}</span>
                    <img src="@/assets/images/arrow-right.svg" />
                </button>
            </div>
            <md-table :value="messages" class="messages-table" md-sort="creationDate" md-sort-order="desc">
                <md-table-row
                    slot-scope="{ item }"
                    :class="{'unread': !item.read && item.messageType_ID === 1}"
                    slot="md-table-row"
                    md-selectable="multiple"
                    @click="toChatPage(item)"
                >
                    <md-table-cell md-label="">{{ item.description }}</md-table-cell>
                    <md-table-cell md-label="Date" md-sort-by="creationDate">{{ getDate(item.creationDate) }}</md-table-cell>

                </md-table-row>
            </md-table>
        </div>
        <div v-if="showCreateNewMessageModal" class="new-message-modal_wrapper">
            <NewMessageModal
                @close="showCreateNewMessageModal = false"
                @saved="addedNewMessage"
            />
        </div>
    </div>
</template>

<script>
import NewMessageModal from "@/components/student-messages/NewMessageModal";
import {mapActions, mapGetters} from "vuex";
export default {
    components: {
        NewMessageModal,
    },
    data: () => ({
        showCreateNewMessageModal: false,
    }),

    computed: {
      ...mapGetters(['messages']),
    },

    methods: {
        ...mapActions(['getMessages', 'readMessage']),
        async toChatPage(item) {
            if (!item.read) {
                const {data} = await this.readMessage(item.id)
                if(data.success && data.read) {
                    await this.getMessages()
                }
            }

            this.$router.push({
                name: 'chat',
                params: {
                    id: item.id
                }
            })
        },
        getDate(date) {
           return date ? this.$moment(date).format('YYYY-MM-DD HH:mm:ss') : ''
        },
        addedNewMessage() {
            this.showCreateNewMessageModal = false;
            this.getMessages()
        },
        // getMessagesMetaInfo() {
        //     this.$axios
        //         .get(process.env.VUE_APP_API_URL + '/bgf/bgfMessageFieldsMetaInfo/')
        //         .then((response) => {
        //             console.log("metainfo = ",response)
        //         })
        //         .catch((error) => {
        //             console.log(error)
        //         })
        // }
    }
}
</script>

<style lang="scss">
.messages-page_wrapper {
    .md-table {
        box-shadow: unset !important;
    }
    .md-table + .md-table {
        margin-top: 16px
    }
    .md-table-row {
        cursor: pointer;
    }
    .md-ripple, .md-table-cell-container {
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        text-align: left;
    }
    .md-table-content {
        table {
            thead {
                tr {
                    th {
                        border-bottom: 1px solid #000000;
                    }
                }
            }
        }
    }

    .md-table-head-label {
        font-weight: bold;
    }
    .md-table-cell-selection .md-checkbox {
        margin: 0 20px 0 0;
    }
    .md-table-sortable-icon {
        opacity: 1 !important;

        svg {
            fill: #0045FF !important;
            height: 18px !important;
        }
    }
}
</style>
<style lang="scss" scoped>
.new-message-modal_wrapper {
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
}
.messages-page {


    &_wrapper {
        width: 100%;
    }

    .unread {
        font-weight: bold;
    }
    &_header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .messages-title {
            font-weight: 900;
            font-size: 36px;
            line-height: 52px;
        }
        .new-messages_btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 200px;
            height: 48px;
            background: #0045FF;
            color: #FFFFFF;
            border-radius: 6px;
            outline: none;
            border: none;
            cursor: pointer;
            span {
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
                margin-right: 10px;
            }
        }
    }

    .messages-table {
        width: 100%;
    }

}
</style>
